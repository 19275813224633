import { css } from '@emotion/react';
import {
  fontSize,
  lineHeight,
  spacing,
} from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid hsl(var(--color-borderLight-8));
  margin-bottom: ${spacing(32)};
  position: relative;
`;

export const rootError = css`
  ${root};
  border-color: hsl(var(--color-error-7));
`;

export const yearError = css`
  ${root};
  border-color: hsl(var(--color-error-7));
  margin-bottom: ${spacing(44)};
`;

export const urlInputWrapper = css`
  display: flex;
  align-items: center;
`;

export const urlInput = css`
  display: block;
  flex: 1;
  outline: 0;
  border-width: 0;
  background: transparent;
  font-size: ${fontSize('base')};
  line-height: ${lineHeight('base')};

  & > div {
    margin: unset;
    padding: 0;

    border: none;
    & > input {
      padding: ${spacing(6.5)} 0;
    }
  }
`;

export const urlLabel = css`
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  color: hsl(var(--color-grayscale-6));
  margin: 0;
`;

export const urlLabelError = css`
  ${urlLabel};
  color: hsl(var(--color-error-7));
`;

export const urlHandle = css`
  margin: 0;
  color: hsl(var(--color-textLight-7));
`;

export const helpContainer = css`
  position: absolute;
  padding-top: ${spacing(4)};
  top: 100%;
  left: 0;
  color: hsl(var(--color-grayscale-4));
  font-size: ${fontSize('tiny')};
  line-height: ${lineHeight('tiny')};
  color: hsl(var(--color-text-1));
`;

export const fieldError = css`
  color: hsl(var(--color-error-7));
  font-size: ${fontSize('base')};
  line-height: calc(var(--unit) * 4);
`;

export const portfolioGrid = css`
  display: grid;

  @media ${breakpoints.medium} {
    column-gap: calc(var(--unit) * 8);
    grid-template-columns: 1fr 2fr;
  }
`;

export const rootFree = css`
  ${root};
  border-color: hsl(var(--color-success-6));
`;

export const portfolioInput = css`
  padding: ${spacing(8)} 0;
`;
